import {
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  VStack,
  VisuallyHidden,
  chakra,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { usePWAInstall } from "react-use-pwa-install";

const Navbar = () => {
  const bg = useColorModeValue("white", "gray.800");
  const mobileNav = useDisclosure();
  const install = usePWAInstall();

  useEffect(() => {
    if (install) {
      install();
    }
  }, [install]);

  // const toast = useToast()
  return (
    <>
      {/* <NavLink to="/">Home</NavLink>
      <br />
      <NavLink to="/company">Smart Phones</NavLink>
      <br />
      <NavLink to="/tablets">Tablets</NavLink>
      <br />
      <NavLink to="/protect">Protections</NavLink>
      <br />
      <NavLink to="/faqs">FAQs</NavLink>
      <br />
      <NavLink to="/policy">Privacy Policy</NavLink>
      <br />{install && <button onClick={install}>Add To Home Screen</button>}
      <NavLink to="/terms">Terms & Conditions</NavLink>
      <br /> */}
      <React.Fragment>
        <chakra.header
          bg={bg}
          w="full"
          px={{
            base: 2,
            sm: 4,
          }}
          py={4}
          shadow="md"
        >
          <Flex alignItems="center" justifyContent="space-between" mx="auto">
            <Flex>
              <chakra.a
                href="/"
                title="Repair Opia Home Page"
                display="flex"
                alignItems="center"
              >
                <VisuallyHidden textColor={"#14c434"}>
                  Repair Opia
                </VisuallyHidden>
              </chakra.a>
              <chakra.h1 fontSize="xl" fontWeight="medium" ml="2">
                <NavLink to="/">
                  <Flex justify="center">
                    <Image
                      src={require("../assets/logo.png")}
                      alt="Repair Opia"
                      rounded="lg"
                      width={{
                        base: "50px",
                        md: "80px",
                        lg: "80px",
                      }}
                      height={{
                        base: "50px",
                        md: "80px",
                        lg: "80px",
                      }}
                      my={{
                        base: 2,
                        lg: 0,
                      }}
                    />
                    <Center>
                      <Heading textColor={"#14c434"}> Repair Opia</Heading>{" "}
                    </Center>
                  </Flex>
                  {/* {install && (
                    <button onClick={install}>Add To Home Screen</button>
                  )} */}
                </NavLink>
              </chakra.h1>
            </Flex>
            <HStack display="flex" alignItems="center" spacing={1}>
              <HStack
                spacing={1}
                mr={1}
                color="brand.500"
                display={{
                  base: "none",
                  md: "inline-flex",
                }}
              >
                <Button fontSize="20px" variant="ghost" textColor={"#14c434"}>
                  <NavLink to="/company">Smart Phones</NavLink>
                </Button>
                <Button fontSize="20px" variant="ghost" textColor={"#14c434"}>
                  <NavLink to="/tablets">Tablets</NavLink>
                </Button>
                <Button fontSize="20px" variant="ghost" textColor={"#14c434"}>
                  <NavLink to="/protect">Protect</NavLink>
                </Button>
                <Button fontSize="20px" variant="ghost" textColor={"#14c434"}>
                  <NavLink to="/product">Shop</NavLink>
                </Button>
                {/* <Button fontSize="20px" variant="ghost" textColor={'#14c434'}>
                  <NavLink to="/policy">Privacy Policy</NavLink>
                </Button>
                <Button fontSize="20px" variant="ghost" textColor={'#14c434'}>
                  <NavLink to="/terms">Terms & Conditions</NavLink>
                </Button> */}
                <Button fontSize="20px" variant="ghost" textColor={"#14c434"}>
                  <NavLink to="/faqs">FAQs</NavLink>
                </Button>
              </HStack>
              {/* <Button colorScheme="brand" size="sm">
                Get Started
              </Button> */}
              <Box
                display={{
                  base: "inline-flex",
                  md: "none",
                }}
              >
                <IconButton
                  display={{
                    base: "flex",
                    md: "none",
                  }}
                  aria-label="Open menu"
                  fontSize="30px"
                  color="#14c434"
                  _dark={{
                    color: "inherit",
                  }}
                  variant="ghost"
                  icon={<AiOutlineMenu color="#14c434" />}
                  onClick={mobileNav.onOpen}
                />

                <VStack
                  pos="absolute"
                  top={0}
                  left={0}
                  right={0}
                  display={mobileNav.isOpen ? "flex" : "none"}
                  flexDirection="column"
                  p={2}
                  pb={4}
                  m={2}
                  bg={bg}
                  spacing={3}
                  rounded="sm"
                  shadow="sm"
                  zIndex={"popover"}
                >
                  <CloseButton
                    aria-label="Close menu"
                    fontSize="30px"
                    color={"#14c434"}
                    onClick={mobileNav.onClose}
                  />

                  {/* <Button w="full" variant="ghost" textColor={'#14c434'}>
                    Features
                  </Button> */}
                  <Button
                    w="full"
                    fontSize="30px"
                    variant="ghost"
                    textColor={"#14c434"}
                  >
                    <NavLink to="/company">Smart Phones</NavLink>
                  </Button>
                  <Button
                    w="full"
                    fontSize="30px"
                    variant="ghost"
                    textColor={"#14c434"}
                  >
                    <NavLink to="/tablets">Tablets</NavLink>
                  </Button>
                  <Button
                    w="full"
                    fontSize="30px"
                    variant="ghost"
                    textColor={"#14c434"}
                  >
                    <NavLink to="/product">Shop</NavLink>
                  </Button>
                  <Button
                    w="full"
                    fontSize="30px"
                    variant="ghost"
                    textColor={"#14c434"}
                  >
                    <NavLink to="/protect">Protect</NavLink>
                  </Button>
                  <Button
                    w="full"
                    fontSize="30px"
                    variant="ghost"
                    textColor={"#14c434"}
                  >
                    <NavLink to="/policy">Privacy Policy</NavLink>
                  </Button>
                  <Button
                    w="full"
                    fontSize="30px"
                    variant="ghost"
                    textColor={"#14c434"}
                  >
                    <NavLink to="/terms">Terms & Conditions</NavLink>
                  </Button>
                  <Button
                    w="full"
                    fontSize="30px"
                    variant="ghost"
                    textColor={"#14c434"}
                  >
                    <NavLink to="/faqs">FAQs</NavLink>
                  </Button>
                </VStack>
              </Box>
            </HStack>
          </Flex>
        </chakra.header>
      </React.Fragment>
    </>
  );
};

export default Navbar;
