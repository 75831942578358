import { Button } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

const Protect = () => {
  return (
    <NavLink to={"/protect"}>
      <Button
        colorScheme="green.200"
        textColor={"#14c434"}
        variant="outline"
        size="md"
        rounded="md"
        boxShadow="md"
      >
        Protect
      </Button>
    </NavLink>
  );
};

export default Protect;
