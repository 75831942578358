import React, { useEffect, useState } from "react";
import { Client } from "../clinet";

import {
  Box,
  SimpleGrid,
  Container,
  Image,
  Center,
  Heading,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import Loading from "../Components/Loading";
const Company = () => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const fetchData = async (value) => {
    const entries = await Client.getEntries({
      content_type: value,
      // select: "fields",
    });
    // console.log(entries.items);
    setData(entries.items);
    return entries.items;
  };
  useEffect(() => {
    setLoading(true);
    fetchData("smartPhones")
      .then
      // (res)=>console.log(res)
      ()
      .finally((res) => {
        // setData(res)
        setLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  // console.log(data);
  if (loading) return <><Loading/></>;
  return (
    <>
      <Container maxWidth="1200px" mx="auto" my="auto" p={{ base: 5, md: 10 }}>
        <Center>
          <Heading color="#14c434" my={"10"}>
            Select Company
          </Heading>
        </Center>
        <SimpleGrid columns={[1, 2, 3]} spacing="55px">
          {data?.map((companies, i) => (
            <Box position="relative" key={i}>
              <NavLink to={`/device/${companies?.fields?.title}`}>
                <Box rounded="lg" overflow="hidden" position="relative">
                  <Center>
                    <Image
                      src={companies?.fields?.image?.fields?.file?.url}
                      alt={companies?.fields?.title}
                    />
                  </Center>
                  <Center>
                    <Box color="#14c434" fontSize="3xl" textAlign={"center"}>
                      {companies?.fields?.title}
                    </Box>
                  </Center>
                </Box>
              </NavLink>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </>
  );
};

export default Company;
