import React, { Suspense, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Client } from "../clinet";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import {
  Box,
  SimpleGrid,
  Container,
  Image,
  Center,
  Text,
  Heading,
} from "@chakra-ui/react";
import Loading from "../Components/Loading";
const Device = () => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [fetchedSeries, setFetchedSeries] = useState();
  const CompanySeries = [];
  const { slug } = useParams();
  const Series = [];
  // console.log(slug);
  const fetchOne = async (value, slug) => {
    const entry = await Client.getEntries({
      content_type: value,
      // limit: 1,
      // include: 10,
      "fields.title": slug,
    });
    // console.log(entry);
    setData(entry.items);
    return entry.items;
  };
  const fetchData = async (value) => {
    const entries = await Client.getEntries({
      content_type: value,
      // select: "fields",
    });
    // console.log(entries.items);
    setFetchedSeries(entries.items);
    return entries.items;
  };
  useEffect(() => {
    setLoading(true);
    fetchOne("smartPhones", slug)
      .then
      // (res)=>console.log(res)
      ()
      .finally(() => {
        // setData(res)
        setLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    fetchData("series")
      .then
      // (res) => console.log(res)
      ()
      .finally(() => {
        // setSeries(res)
        setLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [slug]);
  // console.log(data);
  data?.map((s) =>
    s?.fields?.series?.map((seriesId) => {
      return Series?.push(seriesId?.sys?.id);
    })
  );
  fetchedSeries?.map((s) => {
    Series.map((id) => {
      if (s?.sys?.id === id) {
        // console.log(s?.sys?.id === id);
        CompanySeries.push(s);
      }
      return id;
    });
    return s;
  });
  if (loading) return <><Loading/></>;
  // console.log(CompanySeries);
  if (!data) return <><Loading/></>;
  return (
    <Suspense fallback={<>Loading...</>}>
      <Container maxW="7xl" p={{ base: 5, md: 10 }}>
        {data?.map((company, i) => (
          <Center key={i}>
            <Box textAlign="center">
              <Text fontWeight="extrabold" fontSize="x-large" mb={2}>
                <Box as="span" display="inline-block" position="relative">
                  <Heading color="#14c434" my={"10"}>
                    {company?.fields?.title}
                  </Heading>
                  <Box
                    mt={"-10"}
                    as="span"
                    display="block"
                    position="absolute"
                    bg={"#14c434"}
                    w={"100%"}
                    h={"1px"}
                  />
                </Box>
              </Text>
              <Text>
                {documentToReactComponents(company?.fields?.description)}
              </Text>
            </Box>
          </Center>
        ))}
        {CompanySeries?.map((series, i) => (
          <span key={i}>
            <Center>
              <Box textAlign="center">
                <Text fontWeight="extrabold" fontSize="x-large" mb={2}>
                  <Box as="span" display="inline-block" position="relative">
                    
                    <Heading color="#14c434" my={"10"}>
                    {series?.fields?.seriesName}
                  </Heading>
                  <Box
                    mt={"-10"}
                      as="span"
                      display="block"
                      position="absolute"
                      bg={"#14c434"}
                      w={"100%"}
                      h={"1px"}
                    />
                  </Box>
                </Text>
              </Box>
            </Center>
            <SimpleGrid columns={[1, 3, 4]} spacing="55px">
              {series?.fields?.deviceModels?.map((device, i) => (
                <Box position="relative" key={i}>
                  <NavLink to={`/repair/${device?.fields?.slug}`}>
                    <Box rounded="lg" overflow="hidden" position="relative">
                      <Center>

                      <Image
                        src={device?.fields?.image?.fields?.file?.url}
                        alt={device?.fields?.title}
                      />
                      </Center>
                      <Box p={{ base: 4, lg: 6 }}>
                        <Center>

                          <Box color="#14c434" fontSize="xl" textAlign={"center"}>
                            {device?.fields?.name}
                          </Box>
                        </Center>
                      </Box>
                    </Box>
                  </NavLink>
                </Box>
              ))}
            </SimpleGrid>
          </span>
        ))}
      </Container>
    </Suspense>
  );
};

export default Device;
