import React, { useEffect, useState } from "react";
import { Client } from "../clinet";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  Avatar,
  Box,
  Center,
  Container,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import Loading from "../Components/Loading";

const Protect = () => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const fetchOne = async (value) => {
    const entry = await Client.getEntries({
      content_type: value,
      // limit: 1,
      // include: 10,
      "fields.title": "Protection Offers",
    });
    // console.log(entry);
    setData(entry.items);
    return entry.items;
  };

  useEffect(() => {
    setLoading(true);
    fetchOne("protections")
      .then
      // (res)=>console.log(res)
      ()
      .finally(() => {
        // setData(res)
        setLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  if (loading)
    return (
      <>
        <Loading />
      </>
    );
  //   console.log(data);
  return (
    <div>
      <Container maxW="7xl" p={{ base: 5, md: 10 }}>
        {data?.map((protection, i) => (
          <span key={i}>
            <Center>
              <Box textAlign="center">
                <Text fontWeight="extrabold" fontSize="x-large" mb={2}>
                  <Box as="span" display="inline-block" position="relative">
                    <Heading color="#14c434" my={"10"}>
                      {protection?.fields?.title}
                    </Heading>
                    <Box
                      mt={"-10"}
                      display="block"
                      position="absolute"
                      bg={"#14c434"}
                      w={"100%"}
                      h={"1px"}
                    />
                  </Box>
                </Text>
                <Text>
                  {documentToReactComponents(protection?.fields?.description)}
                </Text>
              </Box>
            </Center>
            <SimpleGrid columns={[1, 2, 3]} spacing="55px" mt={"10"}>
              {protection?.fields?.protectionPackageEntries?.map((entry, j) => (
                <span key={j}>
                  <Box
                    position="relative"
                    key={i}
                    border={"4px"}
                    borderColor={"#42eb61"}
                    rounded={'3xl'}
                  >
                    <Center>
                      <Avatar
                        name="£"
                        src="/"
                        bgColor={"white"}
                        textColor={"#14c434"}
                        border={"4px"}
                        mt={"-8"}
                        size={"lg"}
                      >
                        {entry?.fields?.sku}
                      </Avatar>
                    </Center>
                    <Box rounded="lg" overflow="hidden" position="relative">
                      <Box p={{ base: 4, lg: 6 }}>
                        <Box>
                          <Box
                            color="#14c434"
                            fontSize="xl"
                            textAlign={"center"}
                          >
                            <Text>
                              {documentToReactComponents(
                                entry?.fields?.description
                              )}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </span>
              ))}
            </SimpleGrid>
          </span>
        ))}
      </Container>
    </div>
  );
};

export default Protect;
