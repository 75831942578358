import React from "react";
import { Fragment } from "react";
import {
  Container,
  Flex,
  Stack,
  VStack,
  Divider,
  Avatar,
  Text,
  Button,
} from "@chakra-ui/react";
// import { useNavigate } from "react-router-dom";
import { useShoppingCart } from "use-shopping-cart";
// Here we have used react-icons package for the icon

const Cart = () => {
    // const navigate = useNavigate();
    const { totalPrice, redirectToCheckout, cartDetails, cartCount,  incrementItem, decrementItem } = useShoppingCart()
  console.log(totalPrice, (cartDetails));
//   Object.keys(cartDetails).map((item)=>console.log(cartDetails[item].name))
  return (
    <Container maxW="5xl" p={{ base: 5, md: 10 }}>
      <VStack
        boxShadow="2px 6px 8px rgba(160, 174, 192, 0.6)"
        bg="gray.100"
        rounded="md"
        overflow="hidden"
        spacing={0}
      >
        {Object.keys(cartDetails)?.map((item, index) => (
          <Fragment key={index}>
            <Flex
              w="100%"
              justify="space-between"
              alignItems="center"
              _hover={{ bg: "gray.200" }}
            >
              <Stack spacing={0} direction="row" alignItems="center">
                <Flex p={4}>
                  <Avatar
                    size="md"
                    name={cartDetails[item].name}
                    src={cartDetails[item].image}
                  />
                </Flex>
                <Flex direction="column" p={2}>
                  <Text
                    color="black"
                    fontSize={{ base: "sm", sm: "md", md: "lg" }}
                    // dangerouslySetInnerHTML={{ __html: cartItems.cartItems }}
                  />
                  <Text color="gray.400" fontSize={{ base: "sm", sm: "md" }}>
                    {cartDetails[item].name}
                    <br />
                    <b>Selected Color:</b> {cartDetails[item].color}
                  </Text>
                </Flex>
              </Stack>
              {cartDetails[item].quantity && (
                <Flex p={4}>
                  <Button
                    bgColor="gray.300"
                    rounded={"full"}
                    color={"black"}
                    onClick={() => incrementItem(item)}
                  >
                    +
                  </Button>
                  <Text>{`      ${cartDetails[item].quantity}     `}</Text>
                  <Button
                    bgColor="gray.300"
                    rounded={"full"}
                    color={"black"}
                    onClick={() => decrementItem(cartDetails[item].id)}
                  >
                    -
                  </Button>
                </Flex>
              )}
            </Flex>
            {Object.keys(cartDetails).length - 1 !== index && <Divider m={0} />}
          </Fragment>
        ))}
      </VStack>
        <Button
            rounded={"none"}
            w={"full"}
            mt={8}
            size={"lg"}
            py={"7"}
            bg= "gray.50"
            color= "gray.900"
            textTransform={"uppercase"}
            onClick={async(e) =>{ 
                e.preventDefault()
                if (cartCount > 0) {
                    try {
                      const result = await redirectToCheckout()
                      if (result?.error) {
                        console.error(result)
                      }
                    } catch (error) {
                      console.error(error)
                    }
                  }
                
            }}
            _hover={{
              transform: "translateY(2px)",
              boxShadow: "lg",
            }}
          >
            Checkout 
          </Button>

    </Container>
  );
};

export default Cart;
