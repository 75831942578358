import { Box, Center, Heading, Image } from "@chakra-ui/react";
import React from "react";

const Loading = () => {
  return (
    <Center>
      <Box mt={"20"}>
        <Heading color="#14c434" ml={"28"}>
          LOADING ...
        </Heading>
        <Image
          src={require("../assets/logo.png")}
          alt="Repair Opia logo"
          boxSize={"sm"}
        />
      </Box>
    </Center>
  );
};

export default Loading;
