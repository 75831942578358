import React from "react";
import Hero from "../Components/Hero";
import { Box, SimpleGrid, Container, Image, Center, Heading } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import Protect from "./Protect";

const Home = () => {
  return (
    <>
      <Hero />
      <Container maxWidth="1200px" mx="auto" my="auto" p={{ base: 5, md: 10 }}>
        <Center>

      <Heading color="#14c434" my={"10"}>
         Select Device
        </Heading>
        </Center>
        <SimpleGrid columns={[1, 1, 2]} spacing="55px">
          <Center>
            <Box position="relative">
              <NavLink to="/company">
                <Box rounded="lg" overflow="hidden" position="relative">
                  <Image
                    src={require("../assets/iPhone_XS.png")}
                    alt="smart phone image"
                  />
                  <Box p={{ base: 4, lg: 6 }}>
                    <Box color="gray.600" fontSize="3xl" px={"10"}>
                      Smart Phones
                    </Box>
                  </Box>
                </Box>
              </NavLink>
            </Box>
          </Center>
          <Center>
            <Box position="relative">
              <NavLink to="/tablets">
                <Box rounded="lg" overflow="hidden" position="relative">
                  <Image src={require("../assets/ipad6.png")} alt="tablets" />
                  <Box p={{ base: 4, lg: 6 }}>
                    <Box color="gray.600" fontSize="3xl" px={"20"}>
                      Tablets
                    </Box>
                  </Box>
                </Box>
              </NavLink>
            </Box>
          </Center>
        </SimpleGrid>
        <Protect/>
      </Container>
    </>
  );
};

export default Home;
