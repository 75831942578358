import { Button } from "@chakra-ui/react";
import React from "react";

const Repair = () => {
  return (
    // <NavLink to="">

    <Button
      colorScheme="green.200"
      textColor={"#14c434"}
      variant="outline"
      onClick={() => window.scrollTo(0, 800)}
      size="md"
      rounded="md"
      boxShadow="md"
    >
      Repair
    </Button>
    // </NavLink>
  );
};

export default Repair;
