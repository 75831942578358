import { Button } from '@chakra-ui/react'
import React from 'react'
import { FaWhatsapp } from 'react-icons/fa'

const Whattsapp = () => {
  return (
    <a href='/' target='_blank'>

    <Button
      colorScheme="green.200"
      textColor={"#14c434"}
      variant="outline"
      onClick={() => window.scrollTo(0, 800)}
      size="md"
      rounded="md"
      boxShadow="md"
      leftIcon={<FaWhatsapp size={'40px'}/>}
      
    >
      Whattsapp
    </Button>
    </a>
  )
}

export default Whattsapp