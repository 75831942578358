import React, { useEffect, useState } from "react";
import { Client } from "../clinet";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Text,
  Center,
  Heading,
} from "@chakra-ui/react";
import Loading from "../Components/Loading";

const Terms = () => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const fetchOne = async (value) => {
    const entry = await Client.getEntries({
      content_type: value,
      // limit: 1,
      // include: 10,
      "fields.title": "Terms and conditions",
    });
    // console.log(entry);
    setData(entry.items);
    return entry.items;
  };

  useEffect(() => {
    setLoading(true);
    fetchOne("termsConditions")
      .then
      // (res)=>console.log(res)
      ()
      .finally(() => {
        // setData(res)
        setLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  if (loading)
    return (
      <>
        <Loading />
      </>
    );
  // console.log(data);
  return (
    <div>
      <Container maxW="7xl" p={{ base: 5, md: 10 }}>
        {data?.map((terms, i) => (
          <span key={i}>
            <Center>
              <Box textAlign="center">
                <Text fontWeight="extrabold" fontSize="x-large" mb={2}>
                  <Box as="span" display="inline-block" position="relative">
                    <Heading color="#14c434" my={"10"}>
                      {terms?.fields?.title}
                    </Heading>
                    <Box
                      mt={"-10"}
                      display="block"
                      position="absolute"
                      bg={"#14c434"}
                      w={"100%"}
                      h={"1px"}
                    />
                  </Box>
                </Text>
                <Text>
                  {documentToReactComponents(terms?.fields?.description)}
                </Text>
              </Box>
            </Center>
            {terms?.fields?.entries?.map((entry, j) => (
              <span key={j}>
                {/* <p>{entry?.fields?.title}</p>
              <h5>{documentToReactComponents(entry?.fields?.description)}</h5> */}
                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          {entry?.fields?.title}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      {documentToReactComponents(entry?.fields?.description)}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </span>
            ))}
          </span>
        ))}
      </Container>
    </div>
  );
};

export default Terms;
