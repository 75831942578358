import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import * as serviceWorker from './serviceWorker';
import { CartProvider } from 'use-shopping-cart'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <CartProvider
    mode="payment"
    cartMode="client-only"
    stripe={"pk_test_51HTYVvJS8EkGwgsaQjPmuElszvBhnoMvDChkDDC78raOMpyxw4PGNZV92b5g7IesjIfbvzSGEyszZX7goYwmzmWD00Ch2YdglG"}
    successUrl="https://repair-opia.netlify.app/success"
    cancelUrl="https://repair-opia.netlify.app/failure"
    currency="USD"
    allowedCountries={['US', 'GB', 'CA']}
    billingAddressCollection={true}
  >

    <ChakraProvider>
      <App />
    </ChakraProvider>
  </CartProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();