import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import { GrInstagram } from "react-icons/gr";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <Box
        bg="white"
        _dark={{
          bg: "gray.600",
        }}
      >
        <Stack
          direction={{
            base: "column",
            lg: "row",
          }}
          w="full"
          justify="space-between"
          p={10}
        >
          <Flex justify="center">
            <Image
                src={require('../assets/logo.png')}
              alt="Repair Opia"
              rounded="lg"
              width={{
                base: "150px",
                lg: "150px",
              }}
              height={{
                base: "150px",
                lg: "150px",
              }}
              my={{
                base: 2,
                lg: 0,
              }}
            />
          </Flex>
          <Spacer/>

          <HStack
            alignItems="start"
            flex={1}
            justify="space-around"
            fontSize={{
              base: "12px",
              md: "16px",
            }}
            color="gray.800"
            _dark={{
              color: "white",
            }}
            textAlign={{
              base: "center",
              md: "left",
            }}
          >
            <Flex justify="start" direction="column">
            <Text textTransform="uppercase">About Us</Text>
              <Link textTransform="uppercase">
                <NavLink to="/policy">Privacy Policy</NavLink>
              </Link>
              <Link textTransform="uppercase">
                <NavLink to="/terms">Terms & Conditions</NavLink>
              </Link>
              <Link textTransform="uppercase">
                <NavLink to="/faqs">FAQs</NavLink>
              </Link>
      </Flex>
            <Flex justify="start" direction="column">
              <Text textTransform="uppercase">Services</Text>
              <Link textTransform="uppercase">
                <NavLink to="/company">Smart Phones</NavLink>
              </Link>
              <Link textTransform="uppercase">
                <NavLink to="/tablets">Tablets</NavLink>
              </Link>
              <Link textTransform="uppercase">
                <NavLink to="/protect">Protect</NavLink>
              </Link>
            </Flex>
          </HStack>
          <HStack
            alignItems="start"
            flex={1}
            justify="space-around"
            fontSize={{
              base: "12px",
              md: "16px",
            }}
            color="gray.800"
            _dark={{
              color: "white",
            }}
            textAlign={{
              base: "center",
              md: "left",
            }}
          >
            {/* <Flex justify="start" direction="column">
        <Link textTransform="uppercase">Show Case</Link>
        <Link textTransform="uppercase">Widget Kit</Link>
        <Link textTransform="uppercase">Support</Link>
      </Flex>
            <Flex justify="start" direction="column">
              <Link textTransform="uppercase">About Us</Link>
              <Link textTransform="uppercase">
                <NavLink to="/policy">Privacy Policy</NavLink>
              </Link>
              <Link textTransform="uppercase">
                <NavLink to="/terms">Terms & Conditions</NavLink>
              </Link>
              <Link textTransform="uppercase">
                <NavLink to="/faqs">FAQs</NavLink>
              </Link>
              <Link textTransform="uppercase">Resources</Link>
            </Flex> */}
          </HStack>
        </Stack>
        <Divider
          w="95%"
          mx="auto"
          color="gray.600"
          _dark={{
            color: "#F9FAFB",
          }}
          h="3.5px"
        />
        <VStack py={3}>
          <HStack justify="center">
            <Link>
              <Icon
                color="gray.800"
                _dark={{
                  color: "white",
                }}
                h="20px"
                w="20px"
                as={FaFacebookF}
              />
            </Link>
            <Link>
              <Icon
                _dark={{
                  color: "white",
                }}
                h="20px"
                w="20px"
                as={GrInstagram}
              />
            </Link>
            <Link>
              <Icon
                _dark={{
                  color: "white",
                }}
                h="20px"
                w="20px"
                as={FaTiktok}
              />
            </Link>
          </HStack>

          <Text
            textAlign="center"
            fontSize="smaller"
            _dark={{
              color: "white",
            }}
          >
            &copy; Repair Opia. All rights reserved.
          </Text>
        </VStack>
      </Box>
      ;
    </>
  );
};

export default Footer;
