import React from "react";
import useFetchServices from "../../lib/getData";
import {
  Box,
  Center,
  Container,
  Heading,
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

const Product = () => {
  const [data] = useFetchServices("productCompany");



  // console.log(data);
  if (!data) return <>Loading ...</>;
  return (
    <Container maxW="7xl" p={{ base: 5, md: 10 }}>
      {data?.map((company, i) => (
        <Box>
          <Center key={i}>
            <Box textAlign="center">
              <Text fontWeight="extrabold" fontSize="x-large" mb={2}>
                <Box as="span" display="inline-block" position="relative">
                  <Heading color="#14c434" my={"10"}>
                  {company?.fields?.name}
                  </Heading>
                  <Box
                    mt={"-10"}
                      display="block"
                      position="absolute"
                      bg={"#14c434"}
                    w={"100%"}
                    h={"1px"}
                  />
                </Box>
              </Text>
            </Box>
          </Center>
          <SimpleGrid columns={[1, 3, 4]} spacing="55px">
            {company?.fields?.products.map((product, j)=>
            
              <Box position="relative" key={j}>
                <NavLink to={`/product/${product?.fields?.slug}`}>
                  <Box rounded="lg" overflow="hidden" position="relative">
                    <Image
                      src={product?.fields?.mainImage?.fields?.file?.url}
                      alt={product?.fields?.title}
                    />
                    <Box p={{ base: 4, lg: 6 }}>
                      <Box>
                        <Box color="gray.600" fontSize="xl">
                          {product?.fields?.name}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </NavLink>
              </Box>
            
            )}
          </SimpleGrid>
        </Box>
      ))}
      
    </Container>
  );
};

export default Product;
