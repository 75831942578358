import React, {  useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetchSingleItem from "../../lib/getSingleItem";
import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  List,
  ListItem,
} from "@chakra-ui/react";
import { MdLocalShipping } from "react-icons/md";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useShoppingCart } from "use-shopping-cart";


// This list contains all the data for carousels
// This can be static or loaded from a server
// const cards = [
//   {
//     title: "Design Projects 1",
//     text: "The project board is an exclusive resource for contract work. It's perfect for freelancers, agencies, and moonlighters.",
//     image:
//       "https://images.unsplash.com/photo-1516796181074-bf453fbfa3e6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
//   },
//   {
//     title: "Design Projects 2",
//     text: "The project board is an exclusive resource for contract work. It's perfect for freelancers, agencies, and moonlighters.",
//     image:
//       "https://images.unsplash.com/photo-1438183972690-6d4658e3290e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2274&q=80",
//   },
//   {
//     title: "Design Projects 3",
//     text: "The project board is an exclusive resource for contract work. It's perfect for freelancers, agencies, and moonlighters.",
//     image:
//       "https://images.unsplash.com/photo-1507237998874-b4d52d1dd655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
//   },
// ];
const SingleProduct = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { addItem, decrementItem } = useShoppingCart()
  const [data] = useFetchSingleItem("product", slug);
  const [selectedVarient, setSelectedVarient]=useState(0)
  const productImages = []



    function setImages() {
        let obj = {
            id: data?.fields?.sku,
            image: data?.fields?.mainImage?.fields?.file?.url,
            color: data?.fields?.baseColor,
            colorCode: data?.fields?.baseColorCode,
        }
        productImages.push(obj)
        data?.fields?.productEntries.map((p)=>{
            obj ={
                id: p?.fields?.sku,
                image: p?.fields?.image?.fields?.file?.url,
                color: p?.fields?.color,
                colorCode: p?.fields?.colorCode,
            }
           return productImages.push(obj)
    })
    }
    setImages()


const Product ={
    id: productImages[selectedVarient]?.id,
    name: data?.fields?.name,
    price: (Number(data?.fields?.price )* 100),
    image: productImages[selectedVarient]?.image,
    color: productImages[selectedVarient]?.color,
    colorCode: productImages[selectedVarient]?.colorCode,
  }
// console.log(cartDetails,totalPrice,  Product);

if (!data) return <>Loading ...</>;

  return (
    <Container maxW={"7xl"} mt={""}>
      
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 18, md: 24 }}
      >
        <Flex>

            <Image
            rounded={'md'}
            alt={'product image'}
            src={productImages[selectedVarient]?.image}
            fit={'cover'}
            align={'center'}
            w={'100%'}
            h={{ base: '100%', sm: '400px', md: '500px', lg: '500px' }}
          />
        </Flex>
        <Stack spacing={{ base: 6, md: 10 }}>
          <Box as={"header"}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
            >
              {data?.fields?.name}
            </Heading>
            <Text
              color="gray.900"
              fontWeight={300}
              fontSize={"2xl"}
            >
              £{data?.fields?.price} POUND
            </Text>
          <Text><b>Selected Color:</b> {productImages[selectedVarient]?.color}</Text>
          </Box>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
            pt={"4"}
          >
            {productImages?.map((p, i)=>(
                <Box key={i} >
                    <Button bgColor={p.colorCode} rounded={"full"} color={"white"} onClick={(()=>{setSelectedVarient(i)})}>
                        {/* {p.color} */}
                    </Button>
                </Box>
            ))}
            {/* <Button colorScheme="green">Green</Button>
            <Button colorScheme="purple">Purple</Button> */}
          </Stack>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={"column"}
            divider={<StackDivider borderColor="gray.200" />}
          >
            <VStack spacing={{ base: 4, sm: 6 }}>
              {/* <Text
              color='gray.500'
              fontSize={'2xl'}
              fontWeight={'300'}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
              diam nonumy eirmod tempor invidunt ut labore
            </Text> */}
              <Text fontSize={"lg"}>
                {documentToReactComponents(data?.fields?.description)}
              </Text>
            </VStack>
            <Box>
              <Text
                fontSize={{ base: "16px", lg: "18px" }}
                color="yellow.500"
                fontWeight={"500"}
                textTransform={"uppercase"}
                mb={"4"}
              >
                Features
              </Text>

                <List spacing={2}>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                {data?.fields?.features?.map((f,j)=>(

                  <ListItem key={j}>{f}</ListItem>
                ))}
              </SimpleGrid>
                </List>
            </Box>
            <Box>
              <Text
                fontSize={{ base: "16px", lg: "18px" }}
                color={"yellow.500"}
                fontWeight={"500"}
                textTransform={"uppercase"}
                mb={"4"}
              >
                Product Details
              </Text>

              <List spacing={2}>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    Between lugs:
                  </Text>{" "}
                  20 mm
                </ListItem>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    Bracelet:
                  </Text>{" "}
                  leather strap
                </ListItem>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    Case:
                  </Text>{" "}
                  Steel
                </ListItem>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    Case diameter:
                  </Text>{" "}
                  42 mm
                </ListItem>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    Dial color:
                  </Text>{" "}
                  Black
                </ListItem>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    Crystal:
                  </Text>{" "}
                  Domed, scratch‑resistant sapphire crystal with anti‑reflective
                  treatment inside
                </ListItem>
                <ListItem>
                  <Text as={"span"} fontWeight={"bold"}>
                    Water resistance:
                  </Text>{" "}
                  5 bar (50 metres / 167 feet){" "}
                </ListItem>
              </List>
            </Box>
          </Stack>

          <Button
            rounded={"none"}
            w={"full"}
            mt={8}
            size={"lg"}
            py={"7"}
            bg= "gray.50"
            color= "gray.900"
            textTransform={"uppercase"}
            onClick={() => addItem(Product)}
            _hover={{
              transform: "translateY(2px)",
              boxShadow: "lg",
            }}
          >
            Add This Varient to cart
          </Button>
          <Button
            rounded={"none"}
            w={"full"}
            mt={8}
            size={"lg"}
            py={"7"}
            bg= "gray.50"
            color= "gray.900"
            textTransform={"uppercase"}
            onClick={() => decrementItem(Product.id)}
            _hover={{
              transform: "translateY(2px)",
              boxShadow: "lg",
            }}
          >
            Remove This Varient From Cart
          </Button>
          <Button
            rounded={"none"}
            w={"full"}
            mt={8}
            size={"lg"}
            py={"7"}
            bg= "gray.50"
            color= "gray.900"
            textTransform={"uppercase"}
            onClick={() => navigate("/cart")}
            _hover={{
              transform: "translateY(2px)",
              boxShadow: "lg",
            }}
          >
            Checkout
          </Button>

          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <MdLocalShipping />
            <Text>2-3 business days delivery</Text>
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
};

export default SingleProduct;
