import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import { Client } from "../clinet";
// import { Button, Card, CardBody, CardFooter, CardHeader, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import {
  Box,
  Center,
  Container,
  Image,
  SimpleGrid,
  chakra,
  VStack,
  HStack,
  Text,
  Icon,
  useColorModeValue,
  Heading,
  Avatar,
} from "@chakra-ui/react";
// Here we have used react-icons package for the icons
import { BiCheck } from "react-icons/bi";
import Loading from "../Components/Loading";
import Whattsapp from "../Components/Buttons/Whattsapp";

const Repair = () => {
  const { slug } = useParams();
  // console.log(slug);
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const colorvalue = useColorModeValue("white", "gray.800");
  const fetchOne = async (value, slug) => {
    const entry = await Client.getEntries({
      content_type: value,
      // limit: 1,
      // include: 10,
      "fields.slug": slug,
    });
    // console.log(entry);
    setData(entry.items);
    return entry.items;
  };
  useEffect(() => {
    setLoading(true);
    fetchOne("deviceModel", slug)
      .then
      // (res)=>console.log(res)
      ()
      .finally(() => {
        // setData(res)
        setLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [slug]);
    console.log(data);
  if (loading) return <><Loading/></>;
  return (
    <div>
      <Container maxW="7xl" p={{ base: 5, md: 10 }}>
        {data?.map((device, i) => (
          <span key={i}>
           
              <Box
                // w="3xl"
                bg="white"
                _dark={{ bg: "gray.800" }}
                shadow="lg"
                rounded="lg"
                overflow="hidden"
                mx="auto"
                px={'20px'}
              >
                <Image
                  w="full"
                  h={56}
                  fit="contain"
                  src={device?.fields?.image?.fields?.file?.url}
                  alt={device?.fields?.title}
                />

                <Box py={5} textAlign="center">
                 
                  <Text fontWeight="extrabold" fontSize="x-large" mb={2}>
                <Box as="span" display="inline-block" position="relative">
                  <Heading color="#14c434" my={"10"}>
                  {device?.fields?.name}
                  </Heading>
                  <Box
                    mt={"-10"}
                    as="span"
                    display="block"
                    position="absolute"
                    bg={"#14c434"}
                    w={"100%"}
                    h={"1px"}
                  />
                </Box>
              </Text>
                  <chakra.span
                    fontSize="sm"
                    color="gray.700"
                    _dark={{ color: "gray.200" }}
                  >
                    {device?.fields?.description}
                  </chakra.span>
                </Box>
              </Box>
            <hr />

            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={1} mt={4}>
              {device?.fields?.repairOptions.map((option, j) => (
                <span key={j}>
                  <Box
                    minW={{ base: "xs", sm: "xs", lg: "x  s" }}
                    rounded="lg"
                    bg={colorvalue}
                    boxShadow="md"
                    marginInline="auto"
                    my={3}
                    p={6}
                  >
                    <Box textAlign="center">
                      <Center>
                      <Image
                        src={option?.fields?.icon?.fields?.file?.url}
                        alt={option?.fields?.title}
                        width={'150px'}
                      />
                      </Center>
                      <chakra.h2 fontSize="2xl" fontWeight="bold" textColor={'#14c434'}>
                        {option?.fields.optionName}
                      </chakra.h2>
                      <Text fontSize="3xl" fontWeight="">
                       
                      <Avatar
                        name="£"
                        src="/"
                        bgColor={"white"}
                        textColor={"#14c434"}
                        border={"4px"}
                        // mt={"-8"}
                        size={"lg"}
                      >
                        {option?.fields.price}
                      </Avatar>
                      </Text>
                    </Box>
                    <VStack spacing={2} alignItems="flex-start" my={6}>
                      <HStack spacing={3}>
                        <Icon as={BiCheck} h={4} w={4} color="green.500" />
                        <Text fontSize="sm" color="gray.500">
                          {option?.fields?.description}
                        </Text>
                      </HStack>
                    </VStack>
                    <Whattsapp/>
                  </Box>
                </span>
              ))}
            </SimpleGrid>
          </span>
        ))}
      </Container>
    </div>
  );
};

export default Repair;
