import "./App.css";
import { useEffect, useState } from "react";
import { Client } from "./clinet";

import {
  BrowserRouter as Router,
  // HashRouter,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./Pages/Home";
import Policy from "./Pages/Policy";
import Terms from "./Pages/Terms";
import Tablets from "./Pages/Tablets";
import Navbar from "./Components/Navbar";
import Device from "./Pages/Device";
import Company from "./Pages/Company";
import Repair from "./Pages/Repair";
import Protect from "./Pages/Protect";
import FAQs from "./Pages/FAQs";
import Footer from "./Components/Footer";
import Product from "./Pages/Product/Product";
import SingleProduct from "./Pages/Product/SingleProduct";
import Cart from "./Pages/Product/Cart";
import { CartContextProvider } from "react-use-shoppingcart";
import Seccess from "./Pages/Seccess";
import Failure from "./Pages/Failure";
import Loading from "./Components/Loading";
// import { fetchData } from "./lib/getData";
function App() {
  const [loading, setLoading] = useState();
  // const [data, setData] = useState();
  const fetchData = async (value) => {
    const entries = await Client.getEntries({
      content_type: value,
      // select: "fields",
    });
    // console.log(entries.items);
    // setData(entries.items);
    return entries.items;
  };
  useEffect(() => {
    setLoading(true);
    fetchData("smartPhones")
      .then
      // (res)=>console.log(res)
      ()
      .finally(() => {
        // setData(res)
        setLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  // console.log(data);
  if (loading) return <><Loading/></>;
  return (
    <Router>
      <CartContextProvider>
        {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route exact path="/policy" element={<Policy />} />

          <Route exact path="/terms" element={<Terms />} />

          <Route exact path="/company" element={<Company />} />

          <Route exact path="/protect" element={<Protect />} />
          <Route exact path="/product" element={<Product />} />
          <Route exact path="/product/:slug" element={<SingleProduct />} />
          <Route exact path="/cart" element={<Cart />} />

          <Route exact path="/faqs" element={<FAQs />} />

          <Route exact path="/tablets" element={<Tablets />} />
          <Route
            path="/device/:slug"
            render={(props) => <Device {...props} />}
            element={<Device />}
          />
          <Route
            path="/repair/:slug"
            render={(props) => <Repair {...props} />}
            element={<Repair />}
          />
          
          <Route exact path="/success" element={<Seccess />} />
          
          <Route exact path="/failure" element={<Failure />} />
        </Routes>
        <Footer />
      </CartContextProvider>
    </Router>
  );
}

export default App;
