import { useState, useEffect } from "react";
import {  fetchOne } from "../clinet";

const useFetchSingleItem = (props, slug) => {
    const [data, setData] = useState([]);
    
    // console.log(props, slug);
    useEffect(() => {
        fetchOne(props, slug)
          .then(res => {
            // console.log(res);
            setData(res[0]);
          })
          .catch(err => {
            console.log(err);
          });
      }, [props, slug]);

  return [data];
};

export default useFetchSingleItem;